<template>
    <div class="income-list-box" id="income-list-box">
        <title-nav :title="'收入明细'"/>
        <div class="income">
          <div class="balance">
            <div class="text">收入余额(元)</div>
            <div class="amount">0.00</div>
          </div>
          <div class="bill">
            <div class="text">资金明细</div>
            <div class="data">
              <div class="desc" v-for="list in lists" :key="list.id">
                <div class="type">
                  <div class="name">{{ list.description }}</div>
                  <div class="datetime">{{ list.datetime }}</div>
                </div>
                <div :class="[list.amount > 0 ? 'amount-active' : 'amount']">{{ list.amount > 0 ? '+' + list.amount : list.amount }}</div>
              </div>
              <div class="none" v-if="lists.length === 0">暂无资金明细</div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import { queryWalletByUid  } from '@/api/api'

export default {
  name: 'IncomeList',
  components: {
    TitleNav,
  },
  created(){
    this.getData()
  },
  mounted(){
  },
  data:()=>{
    return {
      lists:[{
        id: 1,
        description:'xxx用户成为合伙人',
        datetime: '2023-06-03 22:22:05',
        amount: 2
      },{
        id: 2,
        description:'xxx用户开通会员',
        datetime: '2023-06-02 22:22:05',
        amount: +2
      },{
        id: 3,
        description:'xxx用户注册新用户',
        datetime: '2023-06-01 22:22:05',
        amount: 2
      }],
      isLogin: localStorage.getItem('token') ? true : false,
    }
  },
  methods:{
    getData(){
      let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
      queryWalletByUid({ uid: uidTemp }).then(res=>{
        console.log(res.data)
      })
    }
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.income-list-box {
    min-height: calc(100vh - 20px);
    padding: 0px 0 20px;
    background: #fff;
    .income {
      .balance {
        padding: 50px 0;
        .text {
          font-size: 14px;
        }
        .amount {
          margin: 10px 0;
          font-size: 36px;
          font-weight: 600;
        }
      }
      .bill {
        .text {
          background: #ededed;
          padding: 5px 15px;
          font-size: 12px;
          text-align: left;
          box-shadow: 0 0 1px rgba(0,0,0,0.1);
        }
        .data {
          .desc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 15px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            .type {
              text-align: left;
              .name {
                font-size: 14px;
              }
              .datetime {
                font-size: 12px;
                margin: 5px 0 10px;
                color: #969699;
              }
            }
            .amount {
            }
            .amount-active {
              color: #f0061d;
            }
          }
          .none {
            margin: 100px auto;
            font-size: 12px;
            color: #969699;
          }
        }
      }
    }
}
</style>